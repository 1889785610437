@import '~antd/dist/antd.css';

/* merriweather-700 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('./fonts/merriweather-v22-latin-700.woff2') format('woff2'),
    url('./fonts/merriweather-v22-latin-700.woff') format('woff');
  font-display: swap;
}

/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('./fonts/source-sans-pro-v14-latin-regular.woff2') format('woff2'),
    url('./fonts/source-sans-pro-v14-latin-regular.woff') format('woff');
  font-display: swap;
}

h1,
h2 {
  font-family: 'Merriweather', 'Georgia', serif;
  font-style: normal;
  font-weight: bolder;
}

body {
  margin: 0;
  font-family: 'Source Sans Pro', 'Segoe UI', 'Roboto', 'Ubuntu', 'Fira Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: cadetblue;
  font-size: 100%;
  text-rendering: optimizeLegibility;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
